class CCOverLayHelper {
    constructor(secureFieldsClient) {
        this._secureFieldsClient = secureFieldsClient;
        this._paymentRegisteredCallback = secureFieldsClient._paymentRegisteredCallback;
    }

    showOverlayWindow(creditCardPaymentInstruments, overlayTranslationData) {

        let modal = document.getElementById("ccOverlayParent");
        if (modal) {
            modal.style.display = "flex";
        }
        let span = document.getElementById("crefopayCCOverlayClose");
        let that = this;

        function clickClose() {
            if (modal) {
                that._paymentRegisteredCallback({resultCode: 6005, message: "cancelled by user"});
                that.hideOverlayWindow();
            }
        }

        if (span) {
            span.addEventListener("click", clickClose);
        }

        let messagesElement = document.getElementById("cc-error-messages");
        if (messagesElement) {
            messagesElement.remove();
        }
        let ccErrorElement = document.getElementById("cc-error");
        ccErrorElement.className = "";

        if (creditCardPaymentInstruments) {
            let modal = document.getElementById("pi-cc-existing");
            let piExistingModal = document.createElement("div");
            piExistingModal.id = "pi-cc-existing-modal";
            modal.appendChild(piExistingModal);
            let piCount = 1;

            creditCardPaymentInstruments.forEach(pi => {
                let div = document.createElement("div");
                let piId = `pi-cc-${piCount}`;
                div.className = "pi-selection";
                let bankAccountElementLabel = document.createElement("label");
                bankAccountElementLabel.className = "pi-selection-label";
                let piElement = document.createElement("input");
                piElement.setAttribute("data-crefopay", "paymentInstrument.id");
                piElement.onclick = function () {
                    let piCreditCardRegisterForm = document.getElementById("piCreditCardRegisterForm");
                    piCreditCardRegisterForm.style.display = 'none';
                };
                piElement.type = "radio";
                piElement.value = pi.paymentInstrumentID;
                piElement.name = "paymentInstrumentCREDITCARD";
                piElement.id = piId;
                bankAccountElementLabel.innerHTML = `<strong>${overlayTranslationData["paymentInstrument.registeredCreditCard"]} (${pi.number})</strong>`;
                bankAccountElementLabel.setAttribute("for", piId);
                div.appendChild(piElement);
                div.appendChild(bankAccountElementLabel);
                piExistingModal.appendChild(div);
                if (piCount === 1) {
                    piElement.setAttribute("checked", "checked");
                    let piCreditCardRegisterForm = document.getElementById("piCreditCardRegisterForm");
                    piCreditCardRegisterForm.style.display = 'none';
                }
                piCount++;
            });
        }
    }

    hideOverlayWindow() {
        let modal = document.getElementById("ccOverlayParent");
        if (modal) {
            modal.style.display = "none";
        }
        let piExistingModal = document.getElementById("pi-cc-existing-modal");
        if (piExistingModal) {
            piExistingModal.remove();
        }
    }

    renderOverlayWindow(overlayTranslationData) {

        let ccOverlayParentModal = document.getElementById("ccOverlayParent");
        if (ccOverlayParentModal) {
            ccOverlayParentModal.remove();
        }

        let overlayModal = document.createElement("div");
        overlayModal.id = "ccOverlayParent";
        overlayModal.style.display = "none";
        const template = document.createElement('div');

        template.innerHTML =
            `<style>
 .crefopay-cc-modal {
    position: fixed; /* Stay in place */
    display: flex;
    /* Stay in place */
     z-index: 999;
    /* Sit on top */
     left: 0;
     top: 0;
     width: 100%;
    /* Full width */
     height: 100%;
    /* Full height */
     overflow: auto;
    /* Enable scroll if needed */
     background-color: rgb(0,0,0);
    /* Fallback color */
     background-color: rgba(0,0,0,0.5);
    /* Black w/ opacity */
     align-items: center;
}
/* Modal Container */
 .crefopay-cc-modal-container {
     background-color: #fefefe;
     margin: auto;  
     padding: 3px;
     border-radius: 5px;
     width: 540px;
     color:gray;
}
/* overlay content */
 .crefopay-cc-modal-content{
     padding: 20px;
}
/* The Close Button */
 .crefopay-overlay-close {
     color: #aaaaaa;
     float: right;
     font-size: 15px;
}
 .crefopay-overlay-close:hover, .crefopay-overlay-close:focus {
     color: #000;
     text-decoration: none;
     cursor: pointer;
}
 .pi-selection{
     margin: 5px;
     padding: 5px;
}
 .pi-selection-label{
     display: contents;
     margin: 5px;
}
 .piCcRegisterForm input{
     width: 200px;
     height: 20px;
     display: inline-block;
     margin: 2px;
}
 .piCcRegisterForm label{
     padding-left: 50px;
     display: inline-block;
     margin: 2px;
     color: #4c4c4c;
}
 .piRegisterCvv label{
     padding-left: 50px;
     color: #4c4c4c;
}
.piRegisterCvv {
    background-color: #efefef;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}
.piCcRegisterForm{
    background-color: #efefef;
}
 .crefopay-cc-modal-content input[type=submit]{
    background-color: #009ee2;
    color: #ffffff;
    font-size: 10px !important;
    background-image: none !important;
    display: inline-block;
    white-space: normal;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    transition: all 0.15s;
    padding: 0 13.5px;
    cursor: pointer;
    min-width: 126px;
    min-height: 27px;
    line-height: 29px;
    outline: none;
    touch-action: manipulation;
    border: none !important;
    border-radius: 0 !important;
    position: relative;
    margin-top: 20px;
    margin-left: 320px;
}
.piCcFromField{
    padding-top: 10px;
    display: flex;
}

.cc-overlay-error {
    background-color: #e05469;
    color: #ffffff;
    padding: 5px;
    padding-left: 25px;
}

        div[data-crefopay-placeholder]{
            width: 200px;
            height: 20px;
            display: inline-block;
            margin: 2px;
        }
        
        div[data-crefopay-placeholder]{
            background-color: white;
            border: 1px solid;
        }
</style>
<div class="crefopay-cc-modal" id="ccOverlayModal">
   <div class="crefopay-cc-modal-container">
      <span class="crefopay-overlay-close" id="crefopayCCOverlayClose">${overlayTranslationData["cc.overlay.close"]}</span>
      <div class="crefopay-cc-modal-content">

          <div id="cc-error"></div>
      <div class="col-md-12">
        <p class="piteaser"></p>
         <p id="ccSubscriptionInfo"> </p>
    </div>
         <div class="pi-existing" id="pi-cc-existing"></div>
    
         <div class="pi-selection"><input type="radio" name="paymentInstrumentCREDITCARD" value="addCreditCard" id="addCreditCard" checked="checked"><label for="addCreditCard" class="pi-selection-label"><strong>${overlayTranslationData["cc.paymentMethod.addPaymentInstrument"]}</strong></label></div>
         <div id="piCreditCardRegisterForm" class="piCcRegisterForm">
         <div class="piCcFromField">
       <div> <label>${overlayTranslationData["cc.paymentMethod.cardHolder"]}</label></div><div data-crefopay-placeholder="paymentInstrument.accountHolder"></div>
         </div>
          <div class="piCcFromField">
         <div><label>${overlayTranslationData["cc.paymentMethod.addPaymentInstrument.card.number"]}</label></div><div data-crefopay-placeholder="paymentInstrument.number"></div>
         </div>
         <div class="piCcFromField">
        <div> <label>${overlayTranslationData["cc.paymentMethod.addPaymentInstrument.card.expiration.date"]}</label></div><div data-crefopay-placeholder="paymentInstrument.validity"></div>
         </div>
      </div>
      <div class="piRegisterCvv">
        <div> <label>${overlayTranslationData["cc.paymentMethod.addPaymentInstrument.card.cvv"]}</label></div><div data-crefopay-placeholder="paymentInstrument.cvv"></div>
      </div>
       <input type="submit" value="${overlayTranslationData["cc.overlay.button"]}" id="ccPiRegisterBtn">
   </div>
</div>`;

        overlayModal.appendChild(template);

        document.body.appendChild(overlayModal);

        let subscriptionInfo = overlayTranslationData["subscription.overlay.info"];
        if (subscriptionInfo) {
            let ccSubscriptionInfoElement = document.getElementById("ccSubscriptionInfo");
            ccSubscriptionInfoElement.textContent = subscriptionInfo;
        }
        let that = this;
        let addCreditCard = document.getElementById("addCreditCard");
        addCreditCard.onclick = function () {
            let piCreditCardRegisterForm = document.getElementById("piCreditCardRegisterForm");
            piCreditCardRegisterForm.style.display = '';
        };

        let ccPiRegisterBtn = document.getElementById("ccPiRegisterBtn");
        ccPiRegisterBtn.onclick = function () {
            that._secureFieldsClient.postRegisterPaymentMessage();
        };
    }

    showErrorMessages(errorMessages, overlayTranslationData) {
        let modal = document.getElementById("cc-error");
        modal.className = "cc-overlay-error";
        let messages = document.getElementById("cc-error-messages");
        if (messages) {
            messages.remove();
        }

        let messagesElement = document.createElement("div");
        messagesElement.id = "cc-error-messages";
        if (errorMessages.errorDetails) {
            errorMessages.errorDetails.forEach(error => {
                let pElement = document.createElement("p");

                let translationKey = error.number == 1002 ? `error.${error.field}.${error.message}` : `error.${error.number}`;

                let translation = overlayTranslationData[translationKey];
                pElement.textContent = translation ? translation : error.description;
                messagesElement.appendChild(pElement);

            });
        } else {
            let pElement = document.createElement("p");
            pElement.textContent = overlayTranslationData["error.5000"];
            messagesElement.appendChild(pElement);
        }
        modal.appendChild(messagesElement);
    }
}

export default CCOverLayHelper;