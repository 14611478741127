export function getMetaTagValue(metaTag) {
    var metaTags = document.querySelectorAll("meta[name='" + metaTag + "']");
    if (metaTags && metaTags.length > 0) {
        return metaTags[0].content ? metaTags[0].content : '';
    }
    return '';
}

export function encodeUrlParams(params) {
    return Object.keys(params).map((key) => key + '=' + encodeURIComponent(params[key])).join('&');
}
