import { getSelectedPaymentMethod, getPaymentInstrumentId, getPaymentInstrument } from './ui_helper';

/**
 * Collects fields in context of main window (merchant owned).
 * 
 * @param {string} token 
 * @param {string} systemUrl 
 * @param {string} sessionId 
 */
const createRegisterPaymentRequest = (token, systemUrl, sessionId) => {
    var paymentMethod = getSelectedPaymentMethod();
    var request = {
        call: 'REGISTER_PAYMENT',
        systemUrl: systemUrl,
        data: {
            sessionId: sessionId,
            paymentMethod: paymentMethod,
            token: token
        },
    };

    request.data.paymentInstrument = getPaymentInstrument(paymentMethod);

    return request;
};

export default createRegisterPaymentRequest;