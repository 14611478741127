const _placeHolderPrefix = 'data-crefopay';

function isEmptyObject(obj) {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
}

/**
 * Helper function for adding key value paris to object
 *
 * @param requestObject
 * @param key String
 * @param value String
 * @param trimSpaces boolean
 */
function addValueToRequest(requestObject, key, value, trimSpaces) {
    if (value) {
        requestObject[key] = trimSpaces ? value.replace(/\s/g, '') : value.trim();
    }
}

/**
 * Get element by element locator, if element locator give more elements they will be filtered by provided filter
 * @param elementLocator
 * @param filter
 * @param defaultValue
 * @returns element valu or default value
 */
function getElementValue(elementLocator, filter, filterThreshold) {
    var element = Array.from(document.querySelectorAll(elementLocator));
    if (element.length > filterThreshold) {
        element =  element.filter(elem => elem.matches(":" + filter));
    }
    return element.length > 0 ? element[0].value : null ;
}

/**
* Get selected payment method. If there is MORE THAN ONE payment method input exists, filter for 'checked'

* Normal case (radio group):
* <input type='radio' data-crefopay='paymentMethod' name='pmt' value='BILL'>
* <input type='radio' data-crefopay='paymentMethod' name='pmt' value='CC' checked='checked'>
*
* Special case (hidden field):
* <input type='hidden' data-crefopay='paymentMethod' value='BILL'>
*
* @returns {string} selected payment method
*/
const getSelectedPaymentMethod = () => {
    return getElementValue("*[" + _placeHolderPrefix + "='paymentMethod']", "checked", 1);
};

/**
 * Get Payment Instrument ID from UI. If there is MORE THAN ZERO payment instrument input exists, filter for 'checked'
 *
 * Use case:
 * <input type='radio' name='pi' value='register new credit card' checked='checked'>
 * <input type='radio' name='pi' data-crefopay='paymentInstrument.id' value='abcd12345ghij'>
 *
 * Mind: the radio for 'register new credit card' is in same group, but has not attribute 'data-crefopay'
 *
 * @returns payment Instrument ID
 */
const getPaymentInstrumentId = () => {
    return getElementValue("*[" + _placeHolderPrefix + "='paymentInstrument.id']", "checked", 0);
};

/**
 * Get payment instrument for payment method (fields from merchant page)
 *
 * @param paymentMethod
 * @returns payment instrument or undefined if there is no fields for payment instrument presented
 */
const getPaymentInstrument = (paymentMethod) => {
    console.log("UiHelper::getPaymentInstrument paymentMethod:" + paymentMethod);
    var paymentInstrument = {};

    switch (paymentMethod) {
        case "CC":
        case "CC3D":
            var paymentInstrumentIdCC = getPaymentInstrumentId();
            addValueToRequest(paymentInstrument, 'paymentInstrumentType', 'CREDITCARD');
            if (paymentInstrumentIdCC) {
                paymentInstrument.paymentInstrumentID = paymentInstrumentIdCC;
            }

            return paymentInstrument;
        case "DD":
            var paymentInstrumentIdDD = getPaymentInstrumentId();
            addValueToRequest(paymentInstrument, 'paymentInstrumentType', 'BANKACCOUNT');
            if (paymentInstrumentIdDD) {
                paymentInstrument.paymentInstrumentID = paymentInstrumentIdDD;
            } else {
                addValueToRequest(paymentInstrument, 'iban',getElementValueSafe("*[" + _placeHolderPrefix + "='paymentInstrument.iban']"), true);
                addValueToRequest(paymentInstrument, 'bic', getElementValueSafe("*[" + _placeHolderPrefix + "='paymentInstrument.bic']"), true);
                addValueToRequest(paymentInstrument, 'accountHolder', getElementValueSafe("*[" + _placeHolderPrefix + "='paymentInstrument.bankAccountHolder']"));
            }
            return isEmptyObject(paymentInstrument) ? undefined : paymentInstrument;
        default:
            return undefined;
    }
};
function getElementValueSafe(selector) {
    let element = document.querySelector(selector);
    return element? element.value : null;
}

export {getSelectedPaymentMethod, getPaymentInstrumentId, getPaymentInstrument};
