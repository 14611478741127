import createRegisterPaymentRequest from './session_request_builder';
import ApiCommunicator from '../common/api_communicator';
import BillieHelper from './billie_helper';
import {getSelectedPaymentMethod} from './ui_helper';
import PaypalHelper from "./paypal_helper";
import Inputmask from "inputmask";
import DDOverLayHelper from "./ddOverlay_helper.js";
import CCOverLayHelper from "./ccOverlay_helper.js";

/**
 * JavaScript lib used for Merchant Integration with PCI SAQ A.
 *
 * Offer ability for merchant to pass PaymentInstrument information from user browser to Session server
 *
 * @param shopPublicKey, public shop identifier
 * @param orderId, order number used by create transaction id
 * @param paymentRegisteredCallback, handler used for handling response
 * @param initializationCompleteCallback, is a function triggered when lib is successfully initialized
 * @param configuration, url of the staging system, if not provided production url will be used, or the configuration object containing any combination of fields
 *                       {url: "", placeholders : { cvv : "", accountHolder : "", number : ""}}
 */
class SecureFieldsClient {

    constructor(shopPublicKey, orderId, paymentRegisteredCallback, initializationCompleteCallback, configuration) {
        console.log('SecureFieldsClient::new');
        SecureFields.unregisterMessageHandler();

        this._token = this.setToken(shopPublicKey);
        this._orderId = orderId;
        this._systemUrl = this.getUrl(configuration);
        this._config = configuration && typeof configuration === 'object' ? configuration.placeholders : undefined;
        this._nonce = configuration && typeof configuration === 'object' ? configuration.nonce : undefined;
        this._showDDOverlay = configuration?.ddOverlay ?? false;
        this._showCCOverlay = configuration?.ccOverlay ?? false;
        this._initializationCompleteCallback = initializationCompleteCallback;
        this._paymentRegisteredCallback = paymentRegisteredCallback;

        this._apiCommunicator = new ApiCommunicator({
            'MAC_HEADER': 'X-UPG-HMAC',
            'TOKEN_HEADER': 'X-UPG-TOKEN',
            'HMAC_ALGORITHM': 'SHA-512',
            'GET_SESSION': {url: this._systemUrl + 'getSession'},
            'START_CHECKOUT': {url: this._systemUrl + 'startCheckout'},
            'CHECKOUT_CONFIRM': {url: this._systemUrl + 'checkoutConfirm'}
        });
        this._billieHelper = new BillieHelper(configuration, this._apiCommunicator);
        this._paypalHelper = new PaypalHelper(configuration, this);

        this._ddOverLayHelper = new DDOverLayHelper(this);
        this._ccOverLayHelper = new CCOverLayHelper(this);
        this.initSession();
    }

    getUrl(configuration) {
        var configUrl = configuration && typeof configuration === 'object' ? configuration['url'] : configuration;
        return configUrl ? configUrl : 'https://api.crefopay.de/secureFields/';
    }

    //Public API

    /**
     * Method for registering the payment which will be used for given transaction.
     */
    registerPayment() {
        console.log('SecureFieldsClient::registerPayment');

        if (!this._sessionId) {
            throw "Session not yet initialized";
        }

        if (getSelectedPaymentMethod() === 'BILL_SECURE') {
            console.log('SecureFieldsClient::registerPayment BILL_SECURE');

            this._apiCommunicator.sendRequest('START_CHECKOUT', {orderNo: this._orderId, token: this._token, paymentMethod: "BILL_SECURE"}, this._token).then((data) => {
                console.log('SecureFieldsClient::start checkout result ' + JSON.stringify(data));
                if (data.resultCode === 0) {
                    this._billieHelper.startCheckout(data).then((result) => {
                        console.log('SecureFieldsClient::registerPayment billie result:' + JSON.stringify(result));

                        if (!result.finished) {
                            return this._apiCommunicator.sendRequest('CHECKOUT_CONFIRM', {orderNo: this._orderId, token: this._token, paymentMethod: "BILL_SECURE", payload: result.payload}, this._token);
                        }
                    }).then((data) => {
                        console.log('SecureFieldsClient::registerPayment checkout confirm:' + JSON.stringify(data));
                        this.postRegisterPaymentMessage();
                    }).catch((error) => {
                        // inform merchant about error
                        if (this._paymentRegisteredCallback) {
                            this._paymentRegisteredCallback(error);
                        }
                    });
                } else {
                    // inform merchant about error
                    if (this._paymentRegisteredCallback) {
                        this._paymentRegisteredCallback(data);
                    }
                }
            });
        } else if (getSelectedPaymentMethod() === 'PAYPAL' && this._paypalHelper._javascriptSdkUrl) {
            if (this._paypalHelper._overlayButton) {
                this._paypalHelper.showOverlayWindow();
            }
        } else if (getSelectedPaymentMethod() === 'DD' && this._showDDOverlay) {
            this._ddOverLayHelper.showOverlayWindow(this._bankAccountPaymentInstruments, this._overlayTranslationData);
        } else if ((getSelectedPaymentMethod() === 'CC' || getSelectedPaymentMethod() === 'CC3D') && this._showCCOverlay) {
            this._ccOverLayHelper.showOverlayWindow(this._creditCardPaymentInstruments, this._overlayTranslationData);
        } else {
            this.postRegisterPaymentMessage();
        }
    }

    // Private functions

    /**
     * Go over form and pickup fields, in case of secure fields that is only PM + PaymentInstrument Information.
     * Post payment data to controller iframe.
     */
    postRegisterPaymentMessage() {
        let registerPaymentRequest = createRegisterPaymentRequest(this._token, this._systemUrl, this._sessionId);

        let dataCrefoPayIFrames = Array.prototype.slice.call(document.querySelectorAll("iframe[data-crefopay-iframe='paymentInstrument.cvv']"))
                .filter(function (item,index) { return item.parentNode.style.display!=="none" && item.parentNode.visibility !== "hidden" &&
                        item.style.display!=="none" && item.visibility !== "hidden"} ).map(item=> item.name);

        if (dataCrefoPayIFrames && dataCrefoPayIFrames.length) {
            registerPaymentRequest.visibleCvvFieldName = dataCrefoPayIFrames;
        }

        console.log('SecureFieldsClient::registerPayment :' + JSON.stringify(registerPaymentRequest) + ", from:" + window.location);

        registerPaymentRequest.type = 'secureFields';

        frames['HostedFields::jsController'].postMessage(registerPaymentRequest, this._systemOrigin);
    }



    checkIfArgumentIsValid(argument, errorMessage) {
        if (typeof (argument) === 'undefined' || argument === null || (argument instanceof String && argument.trim() === '')) {
            throw errorMessage === null ? "invalidArgument" : errorMessage;
        }
    }

    setToken(shopPublicKey) {
        this.checkIfArgumentIsValid(shopPublicKey, "shopPublicKey");
        return shopPublicKey;
    }

    initHostedFields(sysUrl, data) {
        if (this._showDDOverlay) {
            this._ddOverLayHelper.renderOverlayWindow(this._overlayTranslationData);
        }

        if (this._showCCOverlay) {
            this._ccOverLayHelper.renderOverlayWindow(this._overlayTranslationData);
        }

        //Remove existing JsController
        let controller = document.querySelector("#HostedFieldsJsController")
        if (controller) {controller.remove();}

        let payPalOverlayModal = document.getElementById("payPalOverlayModal");
        let payPalOverlayStyleId = document.getElementById("payPalOverlayStyleId");
        if (payPalOverlayModal) {
            payPalOverlayModal.remove();
        }
        if (payPalOverlayStyleId) {
            payPalOverlayStyleId.remove();
        }
        //Create a iframe controller
        var controllerDivElement = document.createElement("div");
        controllerDivElement.setAttribute("id", "HostedFieldsJsController");
        controllerDivElement.setAttribute("style", "display:none; visibility:hidden");

        var controllerIFrameElement = document.createElement("iframe");
        controllerIFrameElement.setAttribute("name", "HostedFields::jsController");
        controllerIFrameElement.setAttribute("src", sysUrl + 'hostedField/jsController/' + this._token + '/?orderId=' + this._orderId + '&nonce=' + this._nonce);
        controllerIFrameElement.setAttribute("height", '0px');
        controllerIFrameElement.setAttribute("width", '0px');
        controllerIFrameElement.setAttribute("scrolling", 'no');
        controllerIFrameElement.setAttribute("frameborder", '0');
        controllerIFrameElement.setAttribute("seamless", 'seamless');

        controllerDivElement.appendChild(controllerIFrameElement);

        document.body.appendChild(controllerDivElement);
        var ccFields = ["paymentInstrument.accountHolder", "paymentInstrument.number", "paymentInstrument.validity", "paymentInstrument.cvv"];

        //Replace placeholders if any with iframes
        document.querySelectorAll("*[data-crefopay-placeholder]").forEach((element, idx) => {
            //remove content
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            var fieldPlaceholder;
            var fieldName = element.getAttribute('data-crefopay-placeholder');
            // since we have multiple cvv fields, add a number to fieldname
            if (ccFields.indexOf(fieldName) > -1) {
                var name = fieldName === "paymentInstrument.cvv" ? fieldName + idx : fieldName;
                if (this._config) {
                    var configName = fieldName.lastIndexOf('.') > 0 ? fieldName.substring(fieldName.lastIndexOf('.') + 1, fieldName.length) : fieldName;
                    fieldPlaceholder = this._config[configName];
                }

                var iFrameElement = document.createElement("iframe");
                iFrameElement.setAttribute("name", name);
                iFrameElement.setAttribute("data-crefopay-iframe", fieldName);
                iFrameElement.setAttribute("src", sysUrl + 'hostedField/field/' + this._token + '/?fieldName=' + fieldName + (fieldPlaceholder ? ('&placeholder=' + fieldPlaceholder) : '') + '&orderId=' + this._orderId + '&nonce=' + this._nonce);
                iFrameElement.setAttribute("scrolling", 'no');
                iFrameElement.setAttribute("frameborder", '0');
                iFrameElement.setAttribute("seamless", 'seamless');
                iFrameElement.setAttribute("width", 'inherit');
                iFrameElement.setAttribute("height", 'inherit');
                iFrameElement.setAttribute("style", 'width:inherit; height:inherit');

                element.appendChild(iFrameElement);
            }
        });

//        let ibanField = document.querySelector("*[data-crefopay='paymentInstrument.iban']");
//        let ibanPlaceholder = " ";
//        if (ibanField && ibanField.getAttribute('placeHolder')) {
//            ibanPlaceholder = ibanField.getAttribute('placeHolder');
//        }

        Inputmask({
            mask: "AA*{2} *{4} *{4} *{4} *{4} *{4} *{4}",
            casing: "upper",
            autoUnmask: true,
            clearMaskOnLostFocus: false,
            placeholder: " "
  //          placeholder: ibanPlaceholder
        }).mask("*[data-crefopay='paymentInstrument.iban']");

        let bicField = document.querySelector("*[data-crefopay='paymentInstrument.bic']");
        if (bicField) {bicField.setAttribute('maxlength', '11');}

        let accountField = document.querySelector("*[data-crefopay='paymentInstrument.bankAccountHolder']");
        if (accountField) {accountField.setAttribute('maxlength', '70');}

        if (data.billie) {
            this._billieHelper.init(data);
        }
        if (data.payPalPayData) {
            data.payPalPayData.nonce = this._nonce;
            this._paypalHelper.init(data);
            this._paypalHelper.loadScript();
        }
    }

    messageReceiver(event) {
        if (event.data && event.data.type === 'secureFields2') {
            console.log('SecureFieldsClient::messageReceiver event: ' + JSON.stringify(event.data) + ' from: ' + event.origin);

            var origin = event.origin ? event.origin : event.originalEvent.origin;
            if (origin === this._systemOrigin) {
                if (this._paymentRegisteredCallback) {
                    delete event.data.type;
                    this._paymentRegisteredCallback(event.data);
                    let selectedPM = getSelectedPaymentMethod();
                    if (selectedPM === 'DD' && this._showDDOverlay) {
                        if (event.data.resultCode === 0) {
                            this._ddOverLayHelper.hideOverlayWindow();
                        } else {
                            this._ddOverLayHelper.showErrorMessages(event.data, this._overlayTranslationData);
                        }
                    } else if ((selectedPM === 'CC' || selectedPM === 'CC3D') && this._showCCOverlay) {
                        if (event.data.resultCode === 0) {
                            this._ccOverLayHelper.hideOverlayWindow();
                        } else {
                            this._ccOverLayHelper.showErrorMessages(event.data, this._overlayTranslationData);
                        }
                    }
                }
            } else {
                console.log('SecureFieldsClient::messageReceiver invalid origin:' + this._systemOrigin);
            }
        } else {
            console.log('SecureFieldsClient::messageReceiver *** unknown *** event: ' + JSON.stringify(event.data) + ' from: ' + event.origin);
        }
    }

    initSession() {
        console.log('SecureFieldsClient::initSession');

        var parts = this._systemUrl.split('/');
        this._systemOrigin = parts[0] + '//' + parts[2];

        this._apiCommunicator.sendRequest('GET_SESSION', {
            orderNo: this._orderId,
            token: this._token,
            showDDOverlay: this._showDDOverlay,
            showCCOverlay: this._showCCOverlay
        }, this._token).then((data) => {
            console.log('SecureFieldsClient::initSession data:' + JSON.stringify(data));
            if (data.resultCode === 0) {
                this._sessionId = data.token;
                this._bankAccountPaymentInstruments = data.bankAccountPaymentInstruments;
                this._creditCardPaymentInstruments = data.creditCardPaymentInstruments;
                this._overlayTranslationData = data.overlayTranslationData;
                this.initHostedFields(this._systemUrl, data);
                SecureFields.registerMessageHandler(this.messageReceiver.bind(this));
            }
            if (this._initializationCompleteCallback) {
                this._initializationCompleteCallback(data);
            }
        });
    }
}

const SecureFields = new class {

    registerMessageHandler(handler) {
        console.log('Register message handler for:' + window.location);
        window._messageHandlerGlobal = handler;
        window.addEventListener('message', window._messageHandlerGlobal);
    }

    unregisterMessageHandler() {
        if (window._messageHandlerGlobal) {
            window.removeEventListener('message', window._messageHandlerGlobal);
            window._messageHandlerGlobal = undefined;
        }
    }
};

export default SecureFieldsClient;
