class BillieHelper {
    constructor(configuration) {
        var billieConfig = configuration && typeof configuration === 'object' ? configuration.billie : undefined;

        this._billieConfig = billieConfig;
    }

    /**
     * Called with GetSession result if billie is active
     * @param {object} data 
     */
    init(data) {
        this._javascriptSdkUrl = data.billie.javascriptSdkUrl;

        console.log("BillieHelper::init url:" + this._javascriptSdkUrl);
    }

    loadScript(url) {
        return new Promise((resolve, reject) => {
            var script = document.createElement('script');
            script.id = 'bcw';
            script.async = 1;
            script.src = url;
            script.onerror = reject;
            script.onload = resolve;

            var prior = document.getElementsByTagName('script')[0];
            prior.parentNode.insertBefore(script, prior);
        }).catch(() => {
            throw new Error("Error loading script from " + url);
        });
    }

    initBillie() {
        console.log("BillieHelper::initBillie script loaded");

        // load billie config
        BillieCheckoutWidget.initHandler();

        return this.waitInitialized();
    }

    waitInitialized() {
        // hack: wait for initHandler to finish (loads async but does not return promise !!!)
        return new Promise((resolve, reject) => {
            const intervalId = window.setInterval(function () {
                try {
                    if (window.__BILLIE_CHECKOUT_CONFIG__ && window.__BILLIE_CHECKOUT_CONFIG__.api_base_url) {
                        window.clearInterval(intervalId);
                        resolve();
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    reject(error);
                }
            }, 10);
        });
    }

    encodeBilleResponse(response) {
        if (response.state || response.decline_reason) {
            return {
                payload: encodeURI(JSON.stringify(response)),
                state: response.state ? response.state : 'declined',
                declineReason: response.decline_reason
            };
        } else {
            return {
                payload: encodeURI(JSON.stringify({ state: 'error' })),
                state: 'error',
                declineReason: undefined
            };
        }
    }

    showBillieDialog(data) {
        console.log("BillieHelper::showBillieDialog");
        var payload = JSON.parse(decodeURIComponent(data.payload));
        if (payload.status) {
            if (payload.status === 'AUTH_SUCESSFUL') {
                // already approved (AUTH_SUCESSFUL)
                console.log("BillieHelper::showBillieDialog already approved");
                return { finished: true };
            }
            if (payload.status === 'AUTH_FAILED') {
                // already declined (AUTH_FAILED)
                var message = "Order is rejected by billie";
                console.log("BillieHelper::showBillieDialog " + message);
                throw new Error(message);
            }
        }
        const billie_config_data = {
            'session_id': data.uuid,
            'merchant_name': ''
        };

        return BillieCheckoutWidget.mount({
            billie_config_data: billie_config_data, billie_order_data: payload
        }).then((result) => {
            // success
            console.log('BillieHelper::showBillieDialog success:' + JSON.stringify(result));
            return this.encodeBilleResponse(result);
        }).catch((result) => {
            console.log("BillieHelper::showBillieDialog decline:" + result.decline_reason);

            switch (result.decline_reason) {
                case 'debtor_address': throw { resultCode: 3001, message: "Debtor address or name mismatch." };
                case 'debtor_not_identified': throw { resultCode: 3002, message: "Debtor could not be identified with the information given." };
                case 'risk_policy': throw { resultCode: 3003, message: "Risk decline." };
                case 'risk_scoring_failed': throw { resultCode: 3004, message: "Not enough data available to make a decision." };
                case 'debtor_limit_exceeded': throw { resultCode: 3005, message: "Financing limit of debtor currently exceeded." };
                case 'suggestion_rejected': throw { resultCode: 3006, message: "User rejected suggested company." };
                default: throw { resultCode: 3099, message: "Unknown decline: " + result.decline_reason };
            }
        });
    }

    /**
     * 
     * @param {object} data from getSession
     * @returns {Promise<string>} a Promise with paymentStatus [AUTH_SUCCESSFUL, AUTH_REQUIRED, AUTH_FAILED])
     */
    async startCheckout(data) {
        console.log("BillieHelper::startCheckout");

        if (this._javascriptSdkUrl) {
            // required by billie library
            window.billieSrc = this._javascriptSdkUrl;

            return this.loadScript(this._javascriptSdkUrl)
                .then(() => this.initBillie())
                .then(() => this.showBillieDialog(data));
        } else {
            var error = { resultCode: 2000, message: "Payment method BILL_SECURE is not active" };
            return Promise.reject(error);

        }
    }
}

export default BillieHelper;