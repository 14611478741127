import {encodeUrlParams} from '../common/helper';
class PaypalHelper {

    constructor(configuration, secureFieldsClient) {
        var paypalConfig = configuration && typeof configuration === 'object' ? configuration.paypal : undefined;

        this._paypalConfig = paypalConfig;
        this._apiCommunicator = secureFieldsClient._apiCommunicator;
        this._orderId = secureFieldsClient._orderId;
        this._token = secureFieldsClient._token;
        this._secureFieldsClient = secureFieldsClient;
        this._paymentRegisteredCallback = secureFieldsClient._paymentRegisteredCallback;
        this._overlayButton = false;
    }

    init(data) {
        let params = {
            'client-id': data.payPalPayData.clientId,
            'intent': data.payPalPayData.intent,
            'currency': data.currency,
            'vault': data.payPalPayData.vault,
            'commit': data.payPalPayData.userAction === 'PAY_NOW' ? true : false,
            'merchant-id': data.payPalPayData.payerId,
            'disable-funding': 'card,credit,blik,giropay,mercadopago,mybank,sepa,venmo,sofort',
            ...((data.payPalPayData.buyerCountry) && {'buyer-country': data.payPalPayData.buyerCountry})
        };
        this._javascriptSdkUrl = data.payPalPayData.javascriptSDKUrl + '?' + encodeUrlParams(params);
        this._overlayTranslatedText = data.payPalPayData.overlayTranslatedText;
        this._overlayTranslatedCloseText = data.payPalPayData.overlayTranslatedCloseText;
        this._nonce = data.payPalPayData.nonce;
        this._paypalReferenceTransaction = data.payPalPayData.vault;

    }

    loadScript() {
        if (!document.querySelector("[data-crefopay-placeholder='payPalButtons']")) {
            this.renderOverlayWindow();
            this._overlayButton = true;
        }

        let url = this._javascriptSdkUrl;
        let paypalConfig = this._paypalConfig;
        let nonce = this._nonce;
        let that = this;
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.id = 'paypalsdk';
            script.async = 1;
            script.src = url;
            script.setAttribute("data-csp-nonce", nonce);
            script.nonce = nonce;
            script.onerror = reject;
            script.onload = () => {
                let payPalOverlayElement = document.getElementById("payPalOverlayButtons");

                if (payPalOverlayElement) {
                    renderPaypalButtons("payPalOverlayButtons", payPalOverlayElement);
                } else {
                    document.querySelectorAll("*[data-crefopay-placeholder]").forEach((element) => {
                        let fieldName = element.getAttribute('data-crefopay-placeholder');

                        if ("payPalButtons".indexOf(fieldName) > -1) {
                            renderPaypalButtons(fieldName, element);
                        }
                    });
                }
            };

            let prior = document.getElementsByTagName('script')[0];
            prior.parentNode.insertBefore(script, prior);
        }).catch((error) => {
            console.log(error);
            throw new Error("Error loading script from " + url);
        });

        function renderPaypalButtons(fieldName, element) {
            let buttonId = fieldName;
            // already have an element on overlay dont need to create
            if (!that._overlayButton) {
                let div = document.createElement("div");
                div.setAttribute("name", fieldName);
                div.setAttribute("id", buttonId);

                element.appendChild(div);
            }

            // Render the PayPal button into #payPalButtons
            let button;
            if (that._paypalReferenceTransaction) {
                button = paypal.Buttons({
                    ...{
                        style: {
                            ...((paypalConfig && paypalConfig.layout) && {layout: paypalConfig.layout}),
                            ...((paypalConfig && paypalConfig.color) && {color: paypalConfig.color}),
                            ...((paypalConfig && paypalConfig.shape) && {shape: paypalConfig.shape}),
                            ...((paypalConfig && paypalConfig.label) && {label: paypalConfig.label}),
                            ...((paypalConfig && paypalConfig.height) && {height: paypalConfig.height}),
                            ...((paypalConfig) && {tagline: paypalConfig.tagline}),
                        }
                    },
                    // Set up the transaction
                    createBillingAgreement: function (data, actions) {
                        return startCheckout();
                    },
                    // Finalize the transaction
                    onApprove: function (data, actions) {
                        return checkoutConfirmAndRegisterPayment(data);
                    },
                    onError: function (error) {
                        console.log("On error occured paypal " + error);
                        that.hideOverlayWindow();
                        // inform merchant about error
                        if (that._paymentRegisteredCallback) {
                            that._paymentRegisteredCallback(error);
                        }
                    },
                    onCancel: function (data, actions) {
                        return checkoutConfirmAndRegisterPayment(data);
                    },
                })
            } else {
                button = paypal.Buttons({
                    ...{
                        style: {
                            ...((paypalConfig && paypalConfig.layout) && {layout: paypalConfig.layout}),
                            ...((paypalConfig && paypalConfig.color) && {color: paypalConfig.color}),
                            ...((paypalConfig && paypalConfig.shape) && {shape: paypalConfig.shape}),
                            ...((paypalConfig && paypalConfig.label) && {label: paypalConfig.label}),
                            ...((paypalConfig && paypalConfig.height) && {height: paypalConfig.height}),
                            ...((paypalConfig) && {tagline: paypalConfig.tagline}),
                        }
                    },
                    // Set up the transaction
                    createOrder: function (data, actions) {
                        return startCheckout();
                    },
                    // Finalize the transaction
                    onApprove: function (data, actions) {
                        return checkoutConfirmAndRegisterPayment(data);
                    },
                    onError: function (error) {
                        console.log("On error occured paypal " + error);
                        that.hideOverlayWindow();
                        // inform merchant about error
                        if (that._paymentRegisteredCallback) {
                            that._paymentRegisteredCallback(error);
                        }
                    },
                    onCancel: function (data, actions) {
                        return checkoutConfirmAndRegisterPayment(data);
                    },
                })
            }
            button.render(`#${buttonId}`);
        }

        function startCheckout() {
            return new Promise(function (resolve, reject) {
                that._apiCommunicator.sendRequest('START_CHECKOUT', {
                    orderNo: that._orderId,
                    token: that._token,
                    paymentMethod: "PAYPAL"
                }, that._token).then(function (data) {
                    if (data.resultCode > 0) {
                        console.log("paypal start Checkout error:" + JSON.stringify(data));
                        reject(data);
                    } else {
                        resolve({orderID: data.uuid});
                    }
                });
            }).then(function (result) {
                return result.orderID;
            });
        }

        function checkoutConfirmAndRegisterPayment(data) {
            that.hideOverlayWindow();
            return new Promise(function (resolve, reject) {
                that._apiCommunicator.sendRequest('CHECKOUT_CONFIRM', {
                    orderNo: that._orderId,
                    token: that._token,
                    paymentMethod: "PAYPAL",
                    payload:JSON.stringify(data)
                }, that._token).then(function (data) {
                    if (data.resultCode > 0) {
                        console.log("paypal Checkout confirm error:" + JSON.stringify(data));
                        reject(data);
                    } else {
                        resolve({orderID: data.orderID});
                    }
                });
            }).then(function () {
                that._secureFieldsClient.postRegisterPaymentMessage();
            });
        }
    }

    showOverlayWindow() {
        let modal = document.getElementById("payPalOverlayModal");
        if (modal) {
            modal.style.display = "flex";
        }

        let span = document.getElementById("crefopayPaypalOverlayClose");
        let that = this;

        function clickClose() {
            if (modal) {
                modal.style.display = "none";
            }
            that._paymentRegisteredCallback({resultCode: 6005, message: "cancelled by user"});
        }

        if (span) {
            span.addEventListener("click", clickClose);
        }

    }

    hideOverlayWindow() {
        let modal = document.getElementById("payPalOverlayModal");
        if (modal) {
            modal.style.display = "none";
        }
    }

    renderOverlayWindow() {
        /** TODO replace css url */
        var styles = `
        /* The Modal (background) */
    .crefopay-paypal-modal {
            display: none; /* Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 999; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
            align-items: center;
        }

        /* Modal Container */
    .crefopay-paypal-modal-container {
            background-color: #fefefe;
            margin: auto;
            padding: 3px;
            border-radius: 5px;
            width: 370px;
            color:gray;
        }

        /*  paypal Buttons */
    .paypal-overlay-buttons {
            padding-left:20%;
            padding-right:20%;
            margin: auto;
        }
        /* overlay content */        
    .crefopay-paypal-modal-content{
        padding: 20px;
        }
        
     /* The Close Button */
    .crefopay-overlay-close {
            color: #aaaaaa;
            float: right;
            font-size: 15px;
        }
    .crefopay-overlay-close:hover,
    .crefopay-overlay-close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }`;

        let styleSheet = document.createElement('style');
        styleSheet.setAttribute("id", "payPalOverlayStyleId");
        document.head.appendChild(styleSheet);
        styleSheet.appendChild(document.createTextNode(styles));

        let overlayModal = document.createElement("div");
        let modalContainer = document.createElement("div");
        let modalContent = document.createElement("div");
        let spanElement = document.createElement("span");
        let paragraphElement = document.createElement("p");
        let spanCloseText = document.createTextNode(this._overlayTranslatedCloseText);

        let paragraphText = document.createTextNode(this._overlayTranslatedText);

        let payPalButtons = document.createElement("div");
        let payPalButtonsContainer = document.createElement("div");

        overlayModal.setAttribute("class", "crefopay-paypal-modal");
        overlayModal.setAttribute("id", "payPalOverlayModal");
        modalContainer.setAttribute("class", "crefopay-paypal-modal-container");
        modalContent.setAttribute("class", "crefopay-paypal-modal-content");
        spanElement.setAttribute("class", "crefopay-overlay-close");
        spanElement.setAttribute("id", "crefopayPaypalOverlayClose");
        payPalButtons.setAttribute('id', 'payPalOverlayButtons');
        payPalButtonsContainer.setAttribute('class', 'paypal-overlay-buttons');

        payPalButtonsContainer.appendChild(payPalButtons);
        spanElement.appendChild(spanCloseText);
        paragraphElement.appendChild(paragraphText);

        modalContainer.appendChild(spanElement);
        modalContent.appendChild(paragraphElement);
        modalContent.appendChild(payPalButtonsContainer);

        modalContainer.appendChild(modalContent);
        overlayModal.appendChild(modalContainer);

        document.body.appendChild(overlayModal);

    }
}

export default PaypalHelper;