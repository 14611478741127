class DDOverLayHelper {

    constructor(secureFieldsClient) {
        this._secureFieldsClient = secureFieldsClient;
        this._paymentRegisteredCallback = secureFieldsClient._paymentRegisteredCallback;
    }

    showOverlayWindow(bankAccountPaymentInstruments, overlayTranslationData) {
        let modal = document.getElementById("ddOverlayParent");
        if (modal) {
            modal.style.display = "flex";

        }
        let span = document.getElementById("crefopayDDOverlayClose");
        let that = this;

        function clickClose() {
            if (modal) {
                that._paymentRegisteredCallback({resultCode: 6005, message: "cancelled by user"});
                that.hideOverlayWindow();
            }
        }

        if (span) {
            span.addEventListener("click", clickClose);
        }
        let messagesElement = document.getElementById("dd-error-messages");
        if (messagesElement) {
            messagesElement.remove();
        }
        let ddErrorElement = document.getElementById("dd-error");
        ddErrorElement.className = "";

        if (bankAccountPaymentInstruments) {
            let modal = document.getElementById("pi-dd-existing");
            let piExistingModal = document.createElement("div");
            piExistingModal.id = "pi-dd-existing-modal";
            modal.appendChild(piExistingModal);
            let piCount = 1;

            bankAccountPaymentInstruments.forEach(pi => {
                let div = document.createElement("div");
                let piId = `pi-${piCount}`;
                div.className = "pi-selection";
                let bankAccountElementLabel = document.createElement("label");
                bankAccountElementLabel.className = "pi-selection-label";
                let piElement = document.createElement("input");
                piElement.setAttribute("data-crefopay", "paymentInstrument.id");
                piElement.onclick = function () {
                    let piRegisterForm = document.getElementById("piRegisterForm");
                    piRegisterForm.style.display = 'none';
                };
                piElement.type = "radio";
                piElement.value = pi.paymentInstrumentID;
                piElement.name = "paymentInstrumentBANKACCOUNT";
                piElement.id = piId;
                bankAccountElementLabel.innerHTML = `<strong>${overlayTranslationData["paymentInstrument.registeredBankAccount"]} (${overlayTranslationData["paymentInstrument.iban"]}  : ${pi.iban})</strong>`;
                bankAccountElementLabel.setAttribute("for", piId);
                div.appendChild(piElement);
                div.appendChild(bankAccountElementLabel);
                piExistingModal.appendChild(div);
                if (piCount === 1) {
                    piElement.setAttribute("checked", "checked");
                    let piRegisterForm = document.getElementById("piRegisterForm");
                    piRegisterForm.style.display = 'none';
                }
                piCount++;
            });
        }
    }

    hideOverlayWindow() {
        let modal = document.getElementById("ddOverlayParent");
        if (modal) {
            modal.style.display = "none";
        }
        let piExistingModal = document.getElementById("pi-dd-existing-modal");
        if (piExistingModal) {
            piExistingModal.remove();
        }
    }

    renderOverlayWindow(overlayTranslationData) {
        let ddOverlayParentModal = document.getElementById("ddOverlayParent");
        if (ddOverlayParentModal) {
            ddOverlayParentModal.remove();
        }

        let overlayModal = document.createElement("div");
        overlayModal.id = "ddOverlayParent";
        overlayModal.style.display = "none";
        const template = document.createElement('div');

        template.innerHTML =
            `<style>
 .crefopay-dd-modal {
    position: fixed; /* Stay in place */
    display:flex;
    /* Stay in place */
     z-index: 999;
    /* Sit on top */
     left: 0;
     top: 0;
     width: 100%;
    /* Full width */
     height: 100%;
    /* Full height */
     overflow: auto;
    /* Enable scroll if needed */
     background-color: rgb(0,0,0);
    /* Fallback color */
     background-color: rgba(0,0,0,0.5);
    /* Black w/ opacity */
     align-items: center;
}
/* Modal Container */
 .crefopay-dd-modal-container {
     background-color: #fefefe;
     margin: auto;
     padding: 3px;
     border-radius: 5px;
     width: 500px;
     color:gray;
}
/* overlay content */
 .crefopay-dd-modal-content{
     padding: 20px;
}
/* The Close Button */
 .crefopay-overlay-close {
     color: #aaaaaa;
     float: right;
     font-size: 15px;
}
 .crefopay-overlay-close:hover, .crefopay-overlay-close:focus {
     color: #000;
     text-decoration: none;
     cursor: pointer;
}
 .pi-selection{
     margin: 5px;
     padding: 5px;
}
 .pi-selection-label{
     display: contents;
     margin: 5px;
}
 .piRegisterForm{
    background-color: #efefef;
    padding-top: 10px;
    padding-bottom: 10px;
}
 .piRegisterForm input{
     width: 200px;
     height: 20px;
     display: inline-block;
     margin: 2px;
}
 .piRegisterForm label{
     padding-left: 50px;
     width: 120px;
     height: 20px;
     display: inline-block;
     margin: 2px;
     color: #4c4c4c;
}
 .crefopay-dd-modal-content input[type=submit]{
    background-color: #009ee2;
    color: #ffffff;
    font-size: 10px !important;
    background-image: none !important;
    display: inline-block;
    white-space: normal;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    transition: all 0.15s;
    padding: 0 13.5px;
    cursor: pointer;
    min-width: 126px;
    min-height: 27px;
    line-height: 29px;
    outline: none;
    touch-action: manipulation;
    border: none !important;
    border-radius: 0 !important;
    position: relative;
    margin-top: 20px;
    margin-left: 320px;
} 
.dd-overlay-error {
    background-color: #e05469;
    color: #ffffff;
    padding: 5px;
    padding-left: 25px;
}        
</style>
<div class="crefopay-dd-modal" id="ddOverlayModal">
   <div class="crefopay-dd-modal-container">
      <span class="crefopay-overlay-close" id="crefopayDDOverlayClose">${overlayTranslationData["dd.overlay.close"]}</span>
      <div class="crefopay-dd-modal-content">
       <div id="dd-error"></div>
      <div class="col-md-12">
        <p class="piteaser"></p>
          <p id="ddSubscriptionInfo"> </p>
          <p class="piinfo">${overlayTranslationData["dd.overlay.info"]}</p>
    </div>
         <div class="pi-existing" id="pi-dd-existing"></div>
    
         <div class="pi-selection"><input type="radio" name="paymentInstrumentBANKACCOUNT" value="addBankAccount" id="addBankAccount" checked="checked"><label for="addBankAccount" class="pi-selection-label"><strong>${overlayTranslationData["paymentInstrument.addPaymentInstrument.title"]}</strong></label></div>
         <div id="piRegisterForm" class="piRegisterForm"><label>${overlayTranslationData["paymentInstrument.iban"]}</label><input type="text" data-crefopay="paymentInstrument.iban"><label>${overlayTranslationData["paymentInstrument.bic"]}</label><input type="text" data-crefopay="paymentInstrument.bic"><label>${overlayTranslationData["paymentInstrument.accountHolder"]}</label><input type="text" data-crefopay="paymentInstrument.bankAccountHolder"></div>
         <input type="submit" value="${overlayTranslationData["dd.overlay.button"]}" id="ddPiRegisterBtn">
      </div>
   </div>
</div>`;

        overlayModal.appendChild(template);

        document.body.appendChild(overlayModal);

        let that = this;
        let addBankAccount = document.getElementById("addBankAccount");
        addBankAccount.onclick = function () {
            let piRegisterForm = document.getElementById("piRegisterForm");
            piRegisterForm.style.display = '';
        };

        let subscriptionInfo = overlayTranslationData["subscription.overlay.info"];
        if (subscriptionInfo) {
            let ddSubscriptionInfoElement = document.getElementById("ddSubscriptionInfo");
            ddSubscriptionInfoElement.textContent = subscriptionInfo;
        }

        let ddPiRegisterBtn = document.getElementById("ddPiRegisterBtn");
        ddPiRegisterBtn.onclick = function () {
            that._secureFieldsClient.postRegisterPaymentMessage();
        };
    }

    showErrorMessages(errorMessages, overlayTranslationData) {
        let modal = document.getElementById("dd-error");
        modal.className = "dd-overlay-error";
        let messages = document.getElementById("dd-error-messages");
        if (messages) {
            messages.remove();
        }

        let messagesElement = document.createElement("div");
        messagesElement.id = "dd-error-messages";
        if (errorMessages.errorDetails) {
            errorMessages.errorDetails.forEach(error => {
                let pElement = document.createElement("p");

                let translationKey = error.number == 1002 ? `error.${error.field}.${error.message}` : `error.${error.number}`;

                let translation = overlayTranslationData[translationKey];
                pElement.textContent = translation ? translation : error.description;
                messagesElement.appendChild(pElement);

            });
        } else {
            let pElement = document.createElement("p");
            pElement.textContent = overlayTranslationData["error.5000"];
            messagesElement.appendChild(pElement);
        }

        modal.appendChild(messagesElement);
    }
}

export default DDOverLayHelper;